import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import ReactDOM from 'react-dom'
import { css } from '@emotion/css'
import useSWR from 'swr'
import { useMutationObserver, useLocalstorage } from 'rooks'
import { Banner } from './Banner'
import { SupportedLanguage, TranslatedNode, TranslatedTextMap, TranslationStatus, TranslationStatusMap } from './models'
import { DropdownOptions } from './options'
import { chunkedArray, existsInside, translate, update_translation } from './util'
import { CustomTreeWalker, getNearestVisibleAncestor } from './customTreeWalker'
import { EditTranslationComponent } from './EditTranslation'
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAnalytics, logEvent } from "firebase/analytics";

const styles = {
  wrap: css`
    width: 160px;
  `,
  gadgetSelect: css`
    width: 100%;
    margin: 4px 0;
  `,
  attribution: css`
    width: 100%;
  `
};

export function Dropdown(props: { options: DropdownOptions }) {
  const { options } = props
  // fetch the supported languages by our provider
  const { data, error: dataError } = useSWR<SupportedLanguage[]>(`${options.endpoints.supportedLanguages}?target=${options.pageLanguage}&siteName=${options.siteName}`);
  const isSupportedLanguageLoading = dataError || !data;
  // store the supported languages seperately from the API call
  const [supportedLanguages, setSupportedLanguages] = useState<SupportedLanguage[]>([ { displayName: 'Select Language', languageCode: '' } ]);
  // for UI
  const [language, setLanguage] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(isSupportedLanguageLoading);
  const [translatedNodes, setTranslatedNodes] = useState<TranslatedNode[]>([]);
  const bodyRef = useRef(document.body);
  const htmlRef = useRef(document.querySelector('html'));
  const [lastLanguage, setLastLanguage] = useLocalstorage('@au5ton/translate-widget/lastLanguage');
  const [customTreeWalker, _] = useState(new CustomTreeWalker(options));
  // XXX: This should eventually come from the widget init and not hardcoded
  const disclaimerMapping = {
    'es': "Para traducir las páginas de su sitio web, la TCEQ utiliza Google Translate, un servicio de traducción automática. La TCEQ no puede garantizar la exactitud de la traducción propuesta. Asimismo, el motor de búsqueda de la TCEQ busca páginas web en inglés, por lo cual, al ingresar términos en español, los resultados de búsqueda podrían ser incompletos."
  }

  let firebaseApp:any;
  let firebaseAnalytics:any;
  let firebaseAppCheck:any;

  const initFirebase = () => {
    if (firebaseApp === undefined && options.firebaseConfig !== undefined){
      // Initialize Firebase
      firebaseApp = initializeApp(options.firebaseConfig);
      firebaseAnalytics = getAnalytics(firebaseApp);

      // Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
      // key is the counterpart to the secret key you set in the Firebase console.
      firebaseAppCheck = initializeAppCheck(firebaseApp, {
        provider: new ReCaptchaV3Provider(options.recaptchaPublicKey),

        // Optional argument. If true, the SDK automatically refreshes App Check
        // tokens as needed.
        isTokenAutoRefreshEnabled: true
      });
    }
  }

  function langName(langCode:string):string {
    let displayName = "";
    const lang = supportedLanguages.find((item) => item.languageCode === langCode);
    if (lang !== undefined){
      displayName = lang.displayName;
    }
    return displayName;
  }

  const preventLinksRedirecting = (event:MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const handleSaveTranslation = async (text:string, initialText:string, targetElement:HTMLElement) => {
    if (options.verboseOutput){
      console.log('English version:', targetElement.dataset.originalText);
      console.log('Automatic translation:', initialText);
      console.log('Manually updated translation:', text);
    }
    initFirebase();
    logEvent(firebaseAnalytics, 'translation_edited');
    const originalText = targetElement.dataset.originalText ?? '';
    const targetLang = targetElement.dataset.targetLang ?? '';
    const result = await update_translation(options.endpoints.updateTranslation, originalText, text, options.pageLanguage, targetLang, options.siteName, firebaseAppCheck);
    if (result) {
      ReactDOM.unmountComponentAtNode(targetElement);
      targetElement.classList.add('skiptranslate');
      targetElement.textContent = text;
      targetElement.removeEventListener('click', preventLinksRedirecting);
    }
  };

  const handleCancelTranslation = (initialText:string, targetElement:HTMLElement) => {
    ReactDOM.unmountComponentAtNode(targetElement);
    targetElement.classList.add('skiptranslate');
    targetElement.textContent = initialText;
    targetElement.removeEventListener('click', preventLinksRedirecting);
  };

  const handleClickEdit = (targetElement:HTMLElement) => {
    const { width, height } = targetElement.getBoundingClientRect();
    const textContent = targetElement.textContent ?? '';

    ReactDOM.render(
        <EditTranslationComponent
        initialText={ textContent }
        targetElement={targetElement}
        initWidth={width}
        initHeight={height}
        onSave={handleSaveTranslation}
        onCancel={handleCancelTranslation}
      />,
      targetElement
    );
    const textarea = targetElement.querySelector('textarea');
    if (textarea) {
      textarea.focus();
    }
  };

  const createEditButton = (handler:(targetElement:HTMLElement) => void, targetElement:HTMLElement) => {
    const buttonId = `editButton_${Date.now()}`; // Generate unique ID
    const button = document.createElement('button');
    button.id = buttonId;
    button.classList.add('btn', 'btn-primary', 'skiptranslate');

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
    svg.setAttribute('width', '16');
    svg.setAttribute('height', '16');
    svg.setAttribute('fill', 'currentColor');
    svg.setAttribute('class', 'plone-icon me-1 bi bi-pencil');
    svg.setAttribute('viewBox', '0 0 16 16');

    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path.setAttribute(
      'd',
      'M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z'
    );

    svg.appendChild(path);

    button.appendChild(svg);

    button.addEventListener('click', (event) => {
      event.stopPropagation();
      event.preventDefault();
      handler(targetElement);
    });

    return { id: buttonId, button };
  };

  const handleHoverIn = (event:MouseEvent) => {
    if (options.endpoints.updateTranslation == ''){
      return;
    }

    if (!event.ctrlKey) {
      return; // Skip if "Ctrl" key is not pressed
    }

    const targetElement = event.target;
    if (targetElement instanceof HTMLElement){
      const { id, button } = createEditButton(handleClickEdit, targetElement);
      targetElement.appendChild(button);
      targetElement.addEventListener('click', preventLinksRedirecting);

      // Store the button ID for later reference
      targetElement.dataset.editButtonId = id;
    }
  };

  const handleHoverOut = (event:MouseEvent) => {
    const targetElement = event.target;
    if (targetElement instanceof HTMLElement){
      const buttonId = targetElement.dataset.editButtonId;
      if (buttonId) {
        const button = document.getElementById(buttonId);
        if (button) {
          button.remove();
        }
        delete targetElement.dataset.editButtonId;
      }
    }
  };

  // Only run on first mount
  useEffect(() => {
    if(lastLanguage !== null) {
      setLanguage(lastLanguage);
    } else {
      setLanguage(options.pageLanguage);
    }
  }, []);

  /**
   * Here, the results from our IntersectionObserver are merged with our existing dataset
   */
  const handleElementIntersection = (entries: IntersectionObserverEntry[]) => {
    setTranslatedNodes(previous => {
      const result = previous.slice();
      // for all items
      for(let item of result) {
        for(let entry of entries) {
          // if we find a matching pair
          if(item.nearestVisibleAncestor && item.nearestVisibleAncestor.isSameNode(entry.target)) {
            item.isIntersecting = entry.isIntersecting;
          }
        }
      }
      return result;
    })
  }

  /**
   * Used for determining if an element is visible in the viewport or not before translating it
   * See: https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver
   */
  const intersectionObserver = useRef(new IntersectionObserver(handleElementIntersection, {
    // https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver/IntersectionObserver#parameters
    // root: document.body,
    // rootMargin?: string;
    threshold: options.intersectionThreshold,
  }))

  /**
   * Whenever changes are made to the DOM, adjust translatedNodes accordingly
   * This includes:
   * - Whenever an element is added or removed
   * - Whenever an element's text changes
   */
  const handleDocumentMutation: MutationCallback = (mutations) => {
    // wrap in this so we don't have race conditions
    setTranslatedNodes(previous => {
      const result = previous.slice();
      for(let mutation of mutations) {
        // If an element is added or removed
        if(mutation.type === 'childList') {
          // Add the added nodes to translatedNodes
          for(let i = 0; i < mutation.addedNodes.length; i++) {
            // use this as a shorthand
            const node = mutation.addedNodes[i];
            // check if this is a Node we even want, reusing our custom filter
            if(customTreeWalker.customFilter.acceptNode(node) === NodeFilter.FILTER_ACCEPT) {
              // make sure this node isn't one we're already watching
              // TODO: rework for attribute nodes
              /**
               * TODO: rework for attribute nodes
               * - we aren't going to mess with attributes here because
               *   we're within the context of customTreeWalker.customFilter.
               *   in this context, we're ONLY dealing with nodeValue/text nodes
               * - change condition to "! existsInside(result, e => ... && e.attributeType === 'text' )"
               *   or something like that
               */
              if(! existsInside(result, e => e.node.isSameNode(node))) {
                const nativeLang: TranslatedTextMap = {};
                nativeLang[options.pageLanguage] = node.nodeValue ?? '';
                const nativeStatus: TranslationStatusMap = {};
                nativeStatus[options.pageLanguage] = TranslationStatus.Translated;

                const ancestor = getNearestVisibleAncestor(node);

                // add to intersection observer
                if(ancestor){
                  intersectionObserver.current.observe(ancestor);
                  // Only add ability to edit translation on non attribute nodes
                  ancestor.dataset.originalText = node.nodeValue ?? '';
                  ancestor.removeEventListener('mouseenter', handleHoverIn);
                  ancestor.removeEventListener('mouseleave', handleHoverOut);
                  ancestor.addEventListener('mouseenter', handleHoverIn);
                  ancestor.addEventListener('mouseleave', handleHoverOut);
                }

                result.push({
                  originalText: node.nodeValue ?? '',
                  currentLanguage: options.pageLanguage,
                  translatedText: {},
                  translationStatus: nativeStatus,
                  node,
                  isIntersecting: false,
                  nearestVisibleAncestor: ancestor,
                  attribute: "_text_",
                });
              }
            }
            // if this is not a Node that we want, maybe it's children are
            else {
              const children = customTreeWalker.textNodesUnder(node);
              for(let child of children) {
                // make sure this node isn't one we're already watching
                // TODO: same as above
                if(! existsInside(result, e => e.node.isSameNode(child))) {
                  const nativeLang: TranslatedTextMap = {};
                  nativeLang[options.pageLanguage] = child.nodeValue ?? '';
                  const nativeStatus: TranslationStatusMap = {};
                  nativeStatus[options.pageLanguage] = TranslationStatus.Translated;

                  const ancestor = getNearestVisibleAncestor(child);

                  // add to intersection observer
                  if(ancestor){
                    intersectionObserver.current.observe(ancestor);
                    // Only add ability to edit translation on non attribute nodes
                    ancestor.dataset.originalText = child.nodeValue ?? '';
                    ancestor.removeEventListener('mouseenter', handleHoverIn);
                    ancestor.removeEventListener('mouseleave', handleHoverOut);
                    ancestor.addEventListener('mouseenter', handleHoverIn);
                    ancestor.addEventListener('mouseleave', handleHoverOut);
                  }

                  result.push({
                    originalText: child.nodeValue ?? '',
                    currentLanguage: options.pageLanguage,
                    translatedText: nativeLang,
                    translationStatus: nativeStatus,
                    node: child,
                    isIntersecting: false,
                    nearestVisibleAncestor: ancestor,
                    attribute: "_text_",
                  });
                }
              }
            }
            // This could be a node with attributes
            if(customTreeWalker.translatableAttributesFilter.acceptNode(node) === NodeFilter.FILTER_ACCEPT) {
              // make sure this node isn't one we're already watching
              for(let attrName of options.includedAttributes) {
                if (node.hasAttribute(attrName)){
                  if(! existsInside(result, e => (e.node.isSameNode(node) && e.attribute !== attrName))) {
                    const nativeLang: TranslatedTextMap = {};
                    nativeLang[options.pageLanguage] = node.getAttribute(attrName);
                    const nativeStatus: TranslationStatusMap = {};
                    nativeStatus[options.pageLanguage] = TranslationStatus.Translated;

                    const ancestor = getNearestVisibleAncestor(node);

                    // add to intersection observer
                    if(ancestor) intersectionObserver.current.observe(ancestor);

                    result.push({
                      originalText: node.getAttribute(attrName),
                      currentLanguage: options.pageLanguage,
                      translatedText: {},
                      translationStatus: nativeStatus,
                      node,
                      isIntersecting: false,
                      nearestVisibleAncestor: ancestor,
                      attribute: attrName,
                    });
                  }
                }
              }
            }
            // if this is not a Node that we want, maybe it's children are
            else {
              // make sure this node isn't one we're already watching
              // TODO: same as above
              const children = customTreeWalker.attributeNodesUnder(node);
              for(let child of children) {
                for(let attrName of options.includedAttributes) {
                  if (child.hasAttribute(attrName)){
                    if(! existsInside(result, e => (e.node.isSameNode(child) && e.attribute !== attrName))) {
                      const nativeLang: TranslatedTextMap = {};
                      nativeLang[options.pageLanguage] = child.getAttribute(attrName);
                      const nativeStatus: TranslationStatusMap = {};
                      nativeStatus[options.pageLanguage] = TranslationStatus.Translated;

                      const ancestor = getNearestVisibleAncestor(child);

                      // add to intersection observer
                      if(ancestor) intersectionObserver.current.observe(ancestor);

                      result.push({
                        originalText: child.getAttribute(attrName),
                        currentLanguage: options.pageLanguage,
                        translatedText: {},
                        translationStatus: nativeStatus,
                        node:child,
                        isIntersecting: false,
                        nearestVisibleAncestor: ancestor,
                        attribute: attrName,
                      });
                    }
                  }
                }
              }
            }
            /**
             * TODO:
             * - above, we checked for nodes which satisfy our criteria for text/"nodeValue" nodes
             * - below, we're going to do the same thing but for "attribute" nodes
             * - a custom tree walker would probably help. in fact, it might be perfect
             *   because it means that we can reuse code and copy/paste patterns that
             *   already work well.
             */

            // TODO: insert code here
          }
          // Remove the removed nodes from translatedNodes
          for(let i = 0; i < mutation.removedNodes.length; i++) {
            // use this as a shorthand
            const node = mutation.removedNodes[i];
            // check if this is a Node we are monitoring, reusing our custom filter
            // TODO: similar to above, make sure this only affects text/"nodeValue" nodes
            if(customTreeWalker.customFilter.acceptNode(node) === NodeFilter.FILTER_ACCEPT) {
              // find the index of this node in translatedNodes
              const index = result.findIndex(e => e.node.isSameNode(node));
              // check if this is a node we're watching
              if(index >= 0) {

                // remove from intersection observer
                /**
                 * TODO: make sure we aren't "unobserve"ing a shared ancestor
                 * - this should only be called if there is only 1 node which has this ancestor value
                 * - if there are multiple nodes that have this ancestor value, just delete this
                 *   node and skip "unobserve"
                 * - when the last node which contains this "ancestor" is removed, this condition
                 *   will kick in and then we will properly "unobserve"
                 */
                if(result[index].nearestVisibleAncestor) intersectionObserver.current.unobserve(result[index].nearestVisibleAncestor!);

                // remove it
                result.splice(index, 1);
              }
            }
            // if this is not a Node that we want, maybe it's children are
            else {
              const children = customTreeWalker.textNodesUnder(node);
              for(let child of children) {
                // find the index of this node in translatedNodes
                const index = result.findIndex(e => e.node.isSameNode(child)); // TODO: inefficient
                // check if this is a node we're watching
                if(index >= 0) {

                  // remove from intersection observer
                  // TODO: similar to above, shared ancestor
                  if(result[index].nearestVisibleAncestor) intersectionObserver.current.unobserve(result[index].nearestVisibleAncestor!);

                  // remove it
                  result.splice(index, 1);
                }
              }
            }

            // TODO: similar to above, process for "attribute" nodes

            // TODO: insert code here
          }
        }
        // If the text of an element changed
        /**
         * TODO:
         * - mutation type 'characterData' ONLY affects text/"nodeValue" nodes!
         * - take action accordingly
         */
        if(mutation.type === 'characterData') {

          // find the index of this node in translatedNodes
          const index = result.findIndex(e => e.node.isSameNode(mutation.target));
          // check if this is a node we're watching
          if(index >= 0) {
            // check if the mutation was NOT as a result of translating, and instead was updated by some other factor
            if(result[index].translationStatus[language] === TranslationStatus.Translated && result[index].translatedText[language] !== mutation.target.nodeValue) {
              // this mutation is one that we didnt make, so we have to update the state so it will be translated in the Effect hook

              // Update information about all languages we've translated to
              // Setting these values will cause the Effect hook to pick up on it
              // clear out old translation data
              for(let key in result[index].translatedText) {
                result[index].translatedText[key] = undefined;
                result[index].translationStatus[key] = TranslationStatus.NotTranslated;
              }

              // Update information about the page's native language
              result[index].originalText = mutation.target.nodeValue ?? '';
              result[index].translatedText[options.pageLanguage] = result[index].originalText;
              result[index].translationStatus[options.pageLanguage] = TranslationStatus.Translated;
              result[index].currentLanguage = options.pageLanguage;
            }
            else {
              // this mutation is one that we probably caused, so we don't want to translate it again
              //console.log('this mutation was probably us')
            }
          }
        }

        /**
         * TODO:
         * - respond to mutation.type === 'attributes'
         * - this will ONLY affect "attribute" nodes
         * - take action accordingly
         */

        // TODO: insert code here
      }
      return result;
    });
  };

  /**
   * Watch the DOM for changes
   * See:
   * - https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
   * - https://www.npmjs.com/package/@rooks/use-mutation-observer
   */
  useMutationObserver(bodyRef, handleDocumentMutation);

  /**
   * update <select> once the languages load for the first time
   */
  useEffect(() => {
    // this condition should only happen on the first load
    if(data !== undefined && supportedLanguages.length === 1) {
      // if we only want a handful of languages to show instead of the entire list
      if(options.preferredSupportedLanguages.length > 1) {
        setSupportedLanguages(x => [
          ...x,
          ...data
            .filter(e => options.preferredSupportedLanguages.includes(e.languageCode))
        ]);
      }
      else {
        setSupportedLanguages(x => [
          ...x,
          ...data
        ]);
      }
    }
  }, [data])

  /**
   * Respond to changes in language
   */
  useEffect(() => {
    if(options.verboseOutput) console.log('language changed');
    if(language !== '' && options.pageLanguage !== language) {
      initFirebase();
      logEvent(firebaseAnalytics, 'language_changed');
      setShowBanner(true);
      if(options.updateDocumentLanguageAttribute) htmlRef.current?.setAttribute('lang', `${language}-x-mtfrom-${options.pageLanguage}`);
    }
    else {
      if(options.updateDocumentLanguageAttribute) htmlRef.current?.setAttribute('lang', options.pageLanguage);
    }
    setLastLanguage(language)
  }, [language])

  /**
   * Initialize translatedNodes and repopulate translations if they are missing
   * This pairs with handleDocumentMutation
   */
  useEffect(() => {
    if(options.verboseOutput) console.log('translatedNodes effect! language: ', language, translatedNodes);
    // if translatedNodes is not initialized, initialize it
    if(translatedNodes.length === 0) {
      // get all leaf text nodes
      const nodes = customTreeWalker.textNodesUnder(document.body);
      // TODO: get nodes with attributes
      // compose our result
      setTranslatedNodes(nodes.map(node => {
        const nativeLang: TranslatedTextMap = {};
        nativeLang[options.pageLanguage] = node.nodeValue ?? ''; // TODO: something else depending on type field
        const nativeStatus: TranslationStatusMap = {};
        nativeStatus[options.pageLanguage] = TranslationStatus.Translated;

        const ancestor = getNearestVisibleAncestor(node);

        // add to intersection observer
        if(ancestor){
          intersectionObserver.current.observe(ancestor);
          // Only add ability to edit translation on non attribute nodes
          ancestor.dataset.originalText = node.nodeValue ?? '';
          ancestor.removeEventListener('mouseenter', handleHoverIn);
          ancestor.removeEventListener('mouseleave', handleHoverOut);
          ancestor.addEventListener('mouseenter', handleHoverIn);
          ancestor.addEventListener('mouseleave', handleHoverOut);
        }

        return {
          originalText: node.nodeValue ?? '',
          currentLanguage: options.pageLanguage,
          translatedText: nativeLang,
          translationStatus: nativeStatus,
          node,
          isIntersecting: false,
          nearestVisibleAncestor: ancestor,
          attribute: '_text_',
        }
      }));

      const attr_nodes = customTreeWalker.attributeNodesUnder(document.body);
      for(let node of attr_nodes){
        for(let attrName of options.includedAttributes) {
          if (node.hasAttribute(attrName) && typeof node.getAttribute(attrName) === 'string'){
            const nativeLang: TranslatedTextMap = {};
            nativeLang[options.pageLanguage] = node.getAttribute(attrName);
            const nativeStatus: TranslationStatusMap = {};
            nativeStatus[options.pageLanguage] = TranslationStatus.Translated;
            const ancestor = getNearestVisibleAncestor(node);

            // add to intersection observer
            if(ancestor) intersectionObserver.current.observe(ancestor);

            setTranslatedNodes( prevList => [...prevList, {
              originalText: node.getAttribute(attrName),
              currentLanguage: options.pageLanguage,
              translatedText: nativeLang,
              translationStatus: nativeStatus,
              node,
              isIntersecting: false,
              nearestVisibleAncestor: ancestor,
              attribute: attrName,
            }]);
          }
        }
      }
    }
    // if translatedNodes is already initialized
    else {
      // check that the language isn't set to the "Select Language" dropdown
      if(language !== '') {
        (async () => {
          // filter translatedNodes to get a list of nodes that aren't translated to the current language, AND that are visible in the viewport currently
          const needsTranslating = translatedNodes
            .filter(e => e.translatedText[language] === undefined &&
              (e.translationStatus[language] === undefined ||
              e.translationStatus[language] === TranslationStatus.NotTranslated) &&
              (options.ignoreIntersection === true || e.isIntersecting === true));

          if(options.verboseOutput) console.log(`needs translating (${needsTranslating.length}): `, needsTranslating);
          // if any translations need to be fetched, do them
          // prevent infinite loop because we'll be setting the state
          if(needsTranslating.length > 0) {
            // we're about to do a bunch of stuff, let's put up a loading spinner
            setIsLoading(true);
            // mark these nodes as "in progress", so our chunked changes don't issue duplicate requests
            setTranslatedNodes(previous => {
              const results = previous.slice();
              for(let i = 0; i < needsTranslating.length; i++) {
                for(let j = 0; j < results.length; j++) {
                  if(needsTranslating[i].node.isSameNode(results[j].node)) {
                    results[j].translationStatus[language] = TranslationStatus.InProgress;
                  }
                }
              }
              return results;
            });
            initFirebase();
            // fetch and apply translations
            for(let chunk of chunkedArray(needsTranslating, options.chunkSize)) {
              // actually do translating
              const data = await translate(options.endpoints.translate, chunk.map(e => e.originalText), options.pageLanguage, language, options.siteName, firebaseAppCheck);
              if (data.status === "error"){
                setErrorMsg(data.message);
              }
              if (data.status === "success"){
                setErrorMsg('');
                setTranslatedNodes(previous => {
                  const results = previous.slice();
                  for(let i = 0; i < chunk.length; i++) {
                    // find where this chunk's node exists in the state
                    const index = results.findIndex(e => (e.node.isSameNode(chunk[i].node) && e.attribute === chunk[i].attribute));
                    // if we could find it, update the state
                    if(index >= 0) {
                      results[index].translatedText[language] = data.data[i];
                      results[index].translationStatus[language] = TranslationStatus.Translated;
                      /**
                       * intentionally don't update the DOM here so we can update the DOM on the next effect call
                       * that will happen as a result of our state changes here
                       */
                      //previous[index].node.nodeValue = previous[index].translatedText[language] ?? '';
                    }
                  }
                  return results;
                });
              }
            }
          }
        })();

        // update the DOM with whatever is stored in the state
        // check if any of the "currentLanguage" is different from the dropdown setting
        if(translatedNodes.some(e => e.currentLanguage !== language && e.translatedText[language] !== undefined && (options.ignoreIntersection === true || e.isIntersecting === true))) {
          setTranslatedNodes(previous => {
            const results = previous.slice();
            // update "currentLanguage" field and update DOM
            for(let i = 0; i < results.length; i++) {
              // if "currentLanguage" is different from the dropdown setting
              if(results[i].currentLanguage !== language && results[i].translatedText[language] !== undefined) {
                results[i].currentLanguage = language;
                if (results[i].attribute === "_text_"){
                  results[i].node.nodeValue = results[i].translatedText[language] ?? ''; // TODO: do something different depending on type
                  if (results[i].nearestVisibleAncestor !== null){
                    results[i].nearestVisibleAncestor.dataset.targetLang = language ?? '';
                  }
                } else {
                  results[i].node.setAttribute(results[i].attribute, results[i].translatedText[language])
                }
              }
            }
            return results;
          })
        }

        if(translatedNodes.some(e => e.translationStatus[language] === TranslationStatus.InProgress)) {
          setIsLoading(true);
        }
        else {
          setIsLoading(false);
        }
      }
    }
  }, [language, translatedNodes]);

  // whenever a new language option is selected
  const handleChange = (languageCode: string) => {
    setLanguage(languageCode);
    setSupportedLanguages(x => {
      // check if placeholder exists
      const placeholderIndex = x.findIndex(e => e.languageCode === '');
      if(placeholderIndex >= 0) {
        // remove if it exists
        x.splice(placeholderIndex, 1);
      }

      // move the native language to the top of the list
      x.unshift(x.splice(x.findIndex(e => e.languageCode === options.pageLanguage), 1)[0]);
      return x;
    });
  }

  /**
   * What happens when the Banner's exit button is clicked
   */
  const handleExit = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setLanguage(options.pageLanguage)
    setShowBanner(false)
  }


  return (
    <div className={`${styles.wrap} skiptranslate`}>
      {options.preferredSupportedLanguages.length === 2 ?
        options.pageLanguage === language ?
          <li className='nav-item'>
          <a href="" className='nav-link' aria-label={langName(options.preferredSupportedLanguages[1])} onClick={e => {e.preventDefault();handleChange(options.preferredSupportedLanguages[1])}}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe" viewBox="0 0 16 16">
              <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
            </svg>
            &nbsp;{langName(options.preferredSupportedLanguages[1]).toUpperCase()}</a>
          </li>
          :
          <li className='nav-item'>
          <a href="" className='nav-link' aria-label={langName(options.preferredSupportedLanguages[0])} onClick={handleExit}>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe" viewBox="0 0 16 16">
              <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
            </svg>
            &nbsp;{langName(options.preferredSupportedLanguages[0]).toUpperCase()}</a>
          </li>
        :
        supportedLanguages.length > 3 ?
          <form>
          <select value={language} onChange={e => handleChange(e.target.value)} className={`${styles.gadgetSelect} form-control form-control-sm`} aria-label="Language Translate Widget">
            {
              supportedLanguages.map(e => <option key={e.languageCode} value={e.languageCode}>{e.displayName}</option>)
            }
          </select>
          </form>
        :
          <></>
      }
      { options.attributionImageUrl ? <img className={styles.attribution} src={options.attributionImageUrl} /> : <></>}
      {showBanner ? createPortal(
        <Banner
          pageLanguage={options.pageLanguage}
          language={language}
          supportedLanguages={supportedLanguages}
          disclaimerMapping={disclaimerMapping}
          logoImageUrl={options.logoImageUrl}
          isLoading={isLoading}
          errorMsg={errorMsg}
          buttons={options.buttons}
          handleExit={handleExit}
          handleLanguageChange={handleChange} />
      , document.getElementById("translation_toolbar_placeholder")) : ''}
    </div>
  );
}
